import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {DimensionConfig} from "./dimension-config";
import {FlashService} from "../flash/flash-service";

@inject(DialogController, DimensionConfig, FlashService)
export class EditDimensionsDialog {

    dimensions = [];

    constructor(dialogController, dimensionConfig, flashService) {
        this.dialogController = dialogController;
        this.dimensionConfig = dimensionConfig;
        this.flashService = flashService;
    }

    activate(context) {
        Object.assign(this, context);
    }

    getAvailableDimensions() {
        let availableDimensions = [];

        for (let dimensionKey of this.dimensionConfig.keys()) {

            if (this.dimensionConfig.isVisible(dimensionKey, this.context, this.mode)) {
                availableDimensions.push(dimensionKey);
            }
        }

        return availableDimensions;
    }

    done() {

        console.log('DIMENSIONS', this.dimensions);

        let hasError = false;
        let i = 0;

        outer:
        for (let date of this.dimensions?.date ?? []) {
            if (!date.from || !date.to) {
                this.flashService.error('Datum: Für alle Saisons müssen gültige Daten eingegeben werden');
                hasError = true;
                break;
            }

            if (date.to < date.from) {
                this.flashService.error('Datum: Bis-Datum muss hinter dem Von-Datum liegen');
                hasError = true;
                break;
            }

            let j = 0;

            for (let subDate of this.dimensions?.date ?? []) {

                if (i === j) {
                    j++;
                    continue;
                }

                if (subDate.from >= date.from && subDate.from <= date.to) {
                    this.flashService.error('Datum: Dimensionen ' + (i + 1) + ' und ' + (j + 1) + ' überlappen sich');
                    hasError = true;
                    break outer;
                }

                if (subDate.to >= date.from && subDate.to <= date.to) {
                    this.flashService.error('Datum: Dimensionen ' + (i + 1) + ' und ' + (j + 1) + ' überlappen sich');
                    hasError = true;
                    break outer;
                }

                j++;
            }

            i++;
        }

        i = 0;

        outer:
        for (let count of this.dimensions?.count ?? []) {
            if (!count.countFrom || !count.countTo) {
                this.flashService.error('PAX: Für alle Zeilen muss von und bis ausgefüllt werden');
                hasError = true;
                continue;
            }

            if (parseFloat(count.countTo) < parseFloat(count.countFrom)) {
                this.flashService.error('PAX: Bis muss größer als Von sein');
                hasError = true;
                continue;
            }

            let j = 0;

            for (let subCount of this.dimensions?.count ?? []) {

                if (i === j) {
                    j++;
                    continue;
                }

                if (parseInt(subCount.countFrom)>= parseInt(count.countFrom) && parseInt(subCount.countFrom) <= parseInt(count.countTo)) {
                    this.flashService.error('PAX: Dimensionen ' + (i + 1) + ' und ' + (j + 1) + ' überlappen sich');
                    hasError = true;
                    break outer;
                }

                if (parseInt(subCount.countTo) >= parseInt(count.countFrom) && parseInt(subCount.countTo) <= parseInt(count.countTo)) {
                    this.flashService.error('PAX: Dimensionen ' + (i + 1) + ' und ' + (j + 1) + ' überlappen sich');
                    hasError = true;
                    break outer;
                }

                j++;
            }

            i++;
        }

        for (let times of this.dimensions?.time ?? []) {
            if (!times.timeFrom) {
                this.flashService.error('Leistungsbeginn: Für alle Zeilen muss die Von-Zeit ausgefüllt sein');
                hasError = true;
                continue;
            }
        }

        if (hasError) {
            return;
        }

        this.dialogController.ok({
            dimensions: this.dimensions,
            countPerStep: this.countPerStep
        });

        return Promise.resolve();
    }

    remove(dimensionKey, index) {
        this.dimensions[dimensionKey].splice(index, 1);
    }

    add(dimensionKey) {
        if (!this.dimensions[dimensionKey]) {
            this.dimensions[dimensionKey] = [];
        }

        this.dimensions[dimensionKey].push({});
    }
}
